/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700&display=swap');
html, body, h1, h2, h3, h4, h5, h6 {
  font-family: 'PT Sans Narrow', sans-serif;
}

body {
  font-size: 20px;
}

.slider-wrapper {
  width: 100%;
  padding: 0 0 0 67px;

  @media(max-width: 480px) {
    padding: 0;
  }
}

.owl-stage-outer {
  background-color: $orange
}

.all-slide .owl-controls .owl-prev i, .all-slide .owl-controls .owl-next i {
  background: $orange;
  color: #333333;
}

.all-slide2 .owl-controls {
  display: none;
}

#scrollUp {
  background: $orange none repeat scroll 0 0;
  color: #333333;
}

.all-testimonial.owl-carousel.owl-theme .owl-dot.active span {
  background: $orange none repeat scroll 0 0;
}

.page-heading, .breadcrumb-list {
  text-shadow: 0px 1px 8px #000;
}

.breadcrumb-list li:last-child a {
  text-transform: none;
  color: $orange;
}

.call-to-action-sec {
  background: $orange;
  padding: 15px 0px;
}

ul {
  list-style: circle;
  padding-left: 17px;
  font-size: 17px;
  color: #333;
}

.pagination.custom-pagination li:hover a {
  background: $orange none repeat scroll 0 0;
  cursor: pointer;
}

.mean-container a.meanmenu-reveal {
  background: $orange;
}

.sec-title h1,
.service-title * {
  text-transform: uppercase;
}

.service-title span {
  text-transform: none;
}

.footer-widget-menu li a {
  text-transform: none;
}

.single-service-inner p,
.single-service-inner li {
  text-align: justify;
}

.top42 {
  margin-top: 42px;
}

.post-meta-info h2 a {
  text-transform: uppercase;
  font-size: 18px;
}

.single-post-text > p {
  text-align: justify;
  text-overflow: ellipsis;
  height: 98px;
  overflow: hidden;
}

.service-cat li a {
  text-transform: none;
}

.main-menu ul li a {
  text-transform: none;
}

.page-heading h1 {
  text-transform: none;
}

.project-thumb {
  min-height: 175px;
  height: auto !important;

  img {
    height: auto;
    width: 100%;
  }
}

.safety {
  .project-thumb img {
    height: auto;
    width: 100%;
  }
}

.main-menu {
  margin-right: 0;

  > ul > li {
    margin-top: -5px;
    > a {
      all: unset;
      max-width: 130px;
      min-width: 53px!important;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      float: left;
      min-width: 67px;
      height: 43px;
      font-size: 15px;
      padding: 17px 8px 0 8px;
      line-height: 23px;
      cursor: pointer;

      &.double-line {
        line-height: 17px;
        height: 48px;
        max-width: 96px;
      }
    }
  }
}

.menu {
  float: right;
  margin-right: 10px;
}

span.destaq {
  background-color: $purple;
  color: #fff;
  padding: 5px;
  width: 100%;
  float: left;
}

.slider-text {
  top: 10%;
  transform: none;

  .slider-caption {
    width: 90%;
  }
}

.container.full-width {
  width: 100%;
}

.why-us-box-text,
.why-us-item-desc p,
.why-us-item-desc p strong {
  text-transform: none;
  text-align: justify;
}

.highlight {
  .single-post {
    border-bottom: none;
  }

  .single-post-text p,
  .post-meta-info h2 a {
    font-size: 2.6vh;
    line-height: 2.9vh;
  }

  .single-post-text p {
    margin-bottom: 0;
  }
}

.mean-container .mean-bar {
  z-index: 999;
}

.post-meta-info h2 {
  height: 60px;
  overflow: hidden;
}

@media(max-width: 480px) {
  .xs-hidden {
    display: none;
  }
  .xs-show {
    display: block;
  }
}

.single-post-text a {
  text-transform: none !important;
}

.roads {
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  thead {
    background-color: $purple;
    tr td {
      color: $orange;
      font-size: 15px;
      font-weight: bold;
    }
  }

  tr td {
    padding: 15px 0;
    color: #333;
    font-weight: bold;
    border-bottom: 1px solid #333;

    &:first-child {
      text-align: left;
      line-height: 18px;
      font-size: 15px;
      min-width: 100px;
      padding-left: 10px;
    }

    &.green {
      color: rgb(83, 158, 70);
    }
    &.yellow {
      color: rgb(158, 128, 70);
    }
    &.red {
      color: rgb(182, 50, 50);
    }
  }

  @media(max-width: 480px) {
    thead {
      font-size: 2.9vw;
    }
    tr td {
      font-size: 4vw;
      &:first-child {
        min-width: 50px;
        width: 50px;
      }
    }
  }
}
